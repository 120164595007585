<template>
  <div />
</template>

<script>
import { oauthGithub } from '@/api'
export default {
  data () {
    return {}
  },
  async created () {
    const code = this.$route.query.code
    const redirect = localStorage.getItem('redirect')
    const token = await oauthGithub(code)
    if (token.access_token) {
      localStorage.setItem('token', token.access_token)
    } else {
      localStorage.removeItem('token')
    }
    // const user = await get('https://api.github.com/user')
    // localStorage.setItem('user', JSON.stringify(user))

    localStorage.removeItem('redirect')
    this.$router.push({
      name: redirect
    })
  }
}
</script>
